import Layout from "../components/layout"
import React from "react"

const PageTemplate = props => {
  console.log(props)

  return (
    <Layout>
      {/*<SEO title={title}/>*/}
      {/*<SlideShow/>*/}
      {/*<Hero image={hero.image}>*/}
      {/*  {children}*/}
      {/*</Hero>*/}
    </Layout>
  )
}

export default PageTemplate
